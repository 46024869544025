import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { AccountLookupTypes } from '@src/app/login/AccountLookupTypes';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain/apiGateway';
import { RootState, RootAction } from '@src/app/_redux';
import { CustomResponse } from '@CDSGlobal/gc-backend';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { actionCreators as authActions } from '@src/app/header/actions';
import {
  INITIATE_OMS_LOOKUP,
  actionCreators as omsActionCreator,
} from '@src/app/login/oms/actions';

import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { concat } from 'rxjs/observable/concat';

export const omsAuthentication = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(INITIATE_OMS_LOOKUP),
    mergeMap(({ payload }) => {
      const accountNumber = payload.accountNumber;
      const postalCode = payload.postalCode;
      const clientConfig = state$.value.clientConfig.clientConfig;
      const adminConfig = state$.value.adminConfig.adminConfig;
      const productExcludes = payload.productExcludes;
      const idpAccountInfo = payload.idpAccountInfo;

      const credentialsObject = state$.value.auth.omsAuth.credentials;

      let defaultMagClientProperty;
      let tableName = 'ClientProperties';
      if (adminConfig[clientConfig.magCode]) {
        defaultMagClientProperty = adminConfig[clientConfig.magCode][tableName];
      }

      let reqPayload: any = {};
      let postBody = {};

      reqPayload = {
        acctNbr: accountNumber,
        postalCode: defaultMagClientProperty.ProductType === 'Core' ? '' : postalCode,
        productExcludes: productExcludes.toString(),
      };

      postBody = {
        filterType: AccountLookupTypes.accountNumberPostalCode,
        magCode: clientConfig.magCode,
        globalCode: clientConfig.globalCode,
        payload: reqPayload,
        idpAccountInfo: idpAccountInfo,
        systemCode: defaultMagClientProperty.ProductType,
      };

      const client = new ApiClient();
      const apiConfig: ApiConfig = {
        credentialsObject: credentialsObject,
        providerType: clientConfig.providerType,
        url: clientConfig.url,
        path: '/GC-call-globalcustomer',
        requestmethod: 'POST',
        region: clientConfig.Region,
        requestbody: postBody,
        parms: {},
        additionalParms: {
          headers: {
            'x-session-id': clientConfig.uuid
          },
        },
      };

      sessionStorage.setItem('loginData', JSON.stringify(postBody));

      const source$ = fromPromise(client.callApiGateway(apiConfig)).pipe(
        map((answer: CustomResponse) => {
          const isSuccess = String(answer.isSuccess) === 'true';
          if (isSuccess) {
            gaActionCreators.createGAEvent({
              category: 'Login by Registered Resin ID User',
              action: 'Successful',
              label: answer.customers[0].prodIdAlias
            });
            return omsActionCreator.omsLookupSuccess(<CustomResponse> answer);
          } else {
            gaActionCreators.createGAEvent({
              category: 'Login by Registered Resin ID User',
              action: 'Unsuccessful',
              label: clientConfig.magCode
            });
            return omsActionCreator.omsLookupFailure(answer);
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: 'Login by Registered Resin ID User',
            action: 'Unknown error',
            label: clientConfig.magCode
          });
          /// if msg contains Invalid login token" then fire logout action with different notifcation.
          if (err &&  err.message.includes('Invalid login token')) {
            omsActionCreator.omsLookupFailureNotification(
              err ? err.message : 'Session Timeout',
            );
            return of(authActions.userLogout()); 
           } else {
            omsActionCreator.omsLookupFailureNotification(
              err ? err.message : 'Unknown error',
            );
            return of(omsActionCreator.omsLookupFailure(err));
          }
        }),
      );

      return concat(
        source$,
        of(omsActionCreator.omsSetCredentials(credentialsObject)),
      );
    }),
  );
